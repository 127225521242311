


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import questionnairesApi from '@/_api/questionnaires.api';
import _cloneDeep from 'lodash.clonedeep';
import {
  QuestionTypes,
  TQuestionnaire,
  TQuestionnaireQuestion,
  TQuestionnaireQuestionAnswerOption
} from '@/_types/questionnaire.type';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import { Getter } from 'vuex-class';
import { TEvent } from '@/_types/event.type';
import _isEqual from 'lodash.isequal';

const RATING_OPTIONS_FROM = 1;
const RATING_OPTIONS_TO = 10;

@Component({
  components: {
    IconSquareDelete,
    SimplePopup,
  }
})
export default class EditQuestionnaire extends Vue {

  @Getter('_eventStore/event') event: TEvent;

  public isSavingInProgress: boolean = false;

  public emptyPollStruct: TQuestionnaire = {
    id: -1,
    title: '',
    questions: []
  }

  public isTranslationInitializationComplete: boolean = false;
  public pollTranslations: {[langCode: string]: TQuestionnaire} = {};
  public lastSavedPollTranslations: {[langCode: string]: TQuestionnaire} = {};

  public emptyQuestionStruct: TQuestionnaireQuestion = {
    id: -1,
    text: '',
    question_type: QuestionTypes.NONE,
    answer_options: [],
    is_enabled: true,
  }

  public emptyAnswerOptionStruct: TQuestionnaireQuestionAnswerOption = {
    id: -1,
    question_id: null,
    text: ''
  }

  public isTypeConfirmationVisible: boolean = false;
  public tempQuestionIndex: number = null;

  public questionTypeNames: typeof QuestionTypes = QuestionTypes;
  public newQuestionTypeName: QuestionTypes = QuestionTypes.NONE;

  public selectedLanguageTab: string = 'en';

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get programId(): number {
    return (this.$route.params.programId && parseInt(this.$route.params.programId, 10)) || null;
  }

  public get eventLanguages(): string[] {
    if (this.event && this.event.languages && this.event.languages.length) {
      return this.event.languages;
    }
    return ['en'];
  }

  public get currentEditLanguage(): string {
    if (this.eventLanguages && this.eventLanguages.length > 0) {
      if (this.eventLanguages.indexOf(this.selectedLanguageTab) > -1) {
        return this.selectedLanguageTab;
      }
      return this.eventLanguages[0];
    }

    return 'en';
  }

  public get poll(): TQuestionnaire {
    if (!this.pollTranslations || !this.pollTranslations[this.currentEditLanguage]) {
      return _cloneDeep(this.emptyPollStruct);
    }
    return this.pollTranslations[this.currentEditLanguage];
  }

  public set poll(value: TQuestionnaire) {
    this.pollTranslations[this.currentEditLanguage] = value;
  }

  public get isLangTabDisabledByLangCode(): {[langCode: string]: boolean} {
    const result: {[langCode: string]: boolean} = {};

    this.eventLanguages.forEach(langCode => {
      result[langCode] = this.isEditMode ? false : (langCode !== 'en');
    });

    return result;
  }

  public get isTranslationSaved(): {[langCode: string]: boolean} {
    const result: {[langCode: string]: boolean} = {};

    this.eventLanguages.forEach(langCode => {
      if (this.isEditMode) {
        result[langCode] = _isEqual(this.pollTranslations[langCode], this.lastSavedPollTranslations[langCode]);
      } else if (langCode === 'en') {
        result[langCode] = _isEqual(this.pollTranslations[langCode], this.lastSavedPollTranslations[langCode]);
      } else {
        result[langCode] = true;
      }
    });

    return result;
  }

  public get areAllTranslationsSaved(): boolean {
    let result = true;

    for (let i = 0; i < this.eventLanguages.length; i++) {
      const checkedLangCode = this.eventLanguages[i];
      if (this.isEditMode) {
        if (!_isEqual(this.pollTranslations[checkedLangCode], this.lastSavedPollTranslations[checkedLangCode])) {
          result = false;
          break;
        }
      } else if (checkedLangCode === 'en') {
        if (!_isEqual(this.pollTranslations[checkedLangCode], this.lastSavedPollTranslations[checkedLangCode])) {
          result = false;
          break;
        }
      }
    }

    return result;
  }

  public get isTopAddQuestionButtonVisible(): boolean {
    return (this.poll.questions.length > 0);
  }

  @Prop({type: Object, default: null})
  public readonly editingPoll: TQuestionnaire;

  @Watch('editingPoll', {immediate: true})
  public onEditingPollChange(): void {
    this.initPollTranslations();
    this.getExistingPollTranslations();
  }

  public get isEditMode(): boolean {
    return !!this.editingPoll;
  }

  public get isSavePollButtonDisabled(): boolean {
    return !this.poll || !this.isPollDataValid() || this.isSavingInProgress;
  }

  public get formTitle(): string {
    if (this.isEditMode) {
      return this.$t('polls.popupTitleEditMode') as string;
    }

    return this.$t('polls.popupTitle') as string;
  }

  private initForm(): void {
    if (!this.editingPoll || !this.pollTranslations || !this.pollTranslations[this.currentEditLanguage]) {
      return;
    }

    this.poll = Object.assign({}, this.pollTranslations[this.currentEditLanguage]);

  }

  private async onSubmitPoll(): Promise<void> {

    if (this.isSavePollButtonDisabled) {
      return;
    }

    if (!this.isPollDataValid()) {
      return; // TODO: display errors
    }

    this.isSavingInProgress = true;

    if (this.isEditMode) {
      await this.editPoll();
    } else {
      const savePollResult: TQuestionnaire = await this.savePoll();
      if (savePollResult && savePollResult.id) {
        this.eventLanguages.forEach(langCode => {
          this.pollTranslations[langCode] = Object.assign({}, this.pollTranslations[langCode], _cloneDeep(savePollResult));
        });
      }
    }

    await this.saveQuestions();
    await this.saveAnswerOptions();

    this.isSavingInProgress = false;

    this.updateLastSavedTranslations(this.currentEditLanguage);

    if (this.areAllTranslationsSaved) {
      this.emitSuccess();
    }

  }

  private isPollDataValid(): boolean {

    if (this.isPollTitleEmpty(this.poll)) {
      return false;
    }

    for (let i = 0; i < this.poll.questions.length; i++) {
      const question = this.poll.questions[i];

      if (
        this.isQuestionWithEmptyTitle(question)
        || this.isQuestionWithEmptyType(question)
        || this.isQuestionLackingAnswerOptions(question)
        || this.isQuestionContainingAnswerOptionsWithEmptyTitles(question)
      ) {
        return false;
      }

    }
    return true;
  }

  private isPollTitleEmpty(poll: TQuestionnaire): boolean {
    return !poll.title.trim();
  }

  private isQuestionWithEmptyTitle(question: TQuestionnaireQuestion): boolean {
    return !question.text.trim();
  }

  private isQuestionWithEmptyType(question: TQuestionnaireQuestion): boolean {
    return !question.question_type;
  }

  private isQuestionLackingAnswerOptions(question: TQuestionnaireQuestion): boolean {
    if (!this.isAnswerOptionsListRequiredForQuestion(question)) {
      return false;
    }
    return (!question.answer_options || question.answer_options.length === 0);
  }

  private isAnswerOptionsListRequiredForQuestion(question: TQuestionnaireQuestion): boolean {
    return (question.question_type === QuestionTypes.MULTI) || (question.question_type === QuestionTypes.OPTION);
  }

  private isQuestionContainingAnswerOptionsWithEmptyTitles(question: TQuestionnaireQuestion): boolean {
    if (question.answer_options) {
      for (let a = 0; a < question.answer_options.length; a++) {
        if (this.isAnswerOptionTitleEmpty(question.answer_options[a])) {
          return true;
        }
      }
    }
    return false;
  }

  private isAnswerOptionTitleEmpty(answerOption: TQuestionnaireQuestionAnswerOption): boolean {
    return answerOption.text.trim() === '';
  }

  private async editPoll(): Promise<boolean> {
    return await questionnairesApi.editQuestionnaire({
      eventId: this.eventId,
      questionnaireId: this.poll.id,
      title: this.poll.title.trim(),
      lang: this.currentEditLanguage === 'en' ? null : this.currentEditLanguage
    });
  }

  private async savePoll(): Promise<TQuestionnaire> {

    const pollTitle: string = this.poll.title.trim();

    const savedPoll: TQuestionnaire = await questionnairesApi.createQuestionnaire({
      eventId: this.eventId,
      programId: this.programId,
      title: pollTitle
    });

    if (this.eventLanguages.length > 1) {
      const otherLanguages: string[] = this.eventLanguages.filter(langCode => langCode !== 'en');

      for (const langCode of otherLanguages) {
        await questionnairesApi.editQuestionnaire({
          eventId: this.eventId,
          questionnaireId: savedPoll.id,
          title: pollTitle,
          lang: langCode
        });
      }
    }

    return savedPoll;
  }

  private async saveQuestions(): Promise<void> {
    if (!this.isEntityInDatabase(this.poll)) {
      return;
    }

    for (const question of this.poll.questions) {
      if (this.isEntityInDatabase(question)) {
        await this.editAQuestion(question);
      } else {
        await this.saveNewQuestion(question);
      }
    }
  }

  private async editAQuestion(question: TQuestionnaireQuestion): Promise<void> {
    await questionnairesApi.editQuestion({
      eventId: this.eventId,
      questionnaireId: this.poll.id,
      questionId: question.id,
      text: question.text,
      questionType: question.question_type,
      isEnabled: true,
      lang: this.currentEditLanguage === 'en' ? null : this.currentEditLanguage
    });
  }

  private async saveNewQuestion(question: TQuestionnaireQuestion): Promise<void> {
    const questionIndex: number = this.poll.questions.findIndex(q => q.id === question.id);
    const questionTranslEnglish: TQuestionnaireQuestion = this.pollTranslations.en.questions[questionIndex];
    const savedQuestion = await questionnairesApi.createQuestion({
      eventId: this.eventId,
      questionnaireId: this.poll.id,
      text: questionTranslEnglish.text || question.text,
      questionType: question.question_type,
      isEnabled: true,
    });
    if (savedQuestion && savedQuestion.id) {
      for (const langCode of this.eventLanguages) {
        const questionTransl: TQuestionnaireQuestion = this.pollTranslations[langCode].questions[questionIndex];
        const titleToSend: string = questionTransl.text && (questionTransl.text !== '') ? questionTransl.text : question.text;
        await questionnairesApi.editQuestion({
          eventId: this.eventId,
          questionnaireId: this.poll.id,
          questionId: savedQuestion.id,
          text: titleToSend,
          questionType: question.question_type,
          isEnabled: true,
          lang: langCode
        });
        this.pollTranslations[langCode].questions[questionIndex].id = savedQuestion.id;
        this.pollTranslations[langCode].questions[questionIndex].text = titleToSend;
      }
    }
  }

  private async saveAnswerOptions(): Promise<void> {

    let questionIndex = 0;

    for (const question of this.poll.questions) {
      if (!this.isSavingAnswerOptionsNeeded(question)) {
        questionIndex++;
        continue;
      }

      if (question.question_type === QuestionTypes.RATING) {
        this.eventLanguages.forEach(langCode => {
          const qTranslation: TQuestionnaireQuestion[] = this.pollTranslations[langCode].questions.filter(q => q.id === question.id);
          qTranslation.forEach(q => {
            this.addRatingOptions(q);
          });
        });
      }

      let answerOptionIndex = 0;

      for (const answerOption of question.answer_options) {
        if (!this.isEntityInDatabase(answerOption)) {
          const answOptTranslEnglish: TQuestionnaireQuestionAnswerOption = this.pollTranslations.en.questions[questionIndex].answer_options[answerOptionIndex];
          const savedOption = await questionnairesApi.createAnswerOption({
            eventId: this.eventId,
            questionnaireId: this.poll.id,
            questionId: question.id,
            text: answOptTranslEnglish.text || answerOption.text
          });
          if (savedOption && savedOption.id) {
            for (const langCode of this.eventLanguages) {
              const answOptTranslation = this.pollTranslations[langCode].questions[questionIndex].answer_options[answerOptionIndex];
              const titleToSend: string = answOptTranslation.text && (answOptTranslation.text !== '') ? answOptTranslation.text : answerOption.text;
              await questionnairesApi.editAnswerOption({
                eventId: this.eventId,
                questionnaireId: this.poll.id,
                questionId: question.id,
                answerOptionId: savedOption.id,
                text: titleToSend,
                lang: langCode
              });
              answOptTranslation.id = savedOption.id;
              answOptTranslation.question_id = question.id;
              answOptTranslation.text = titleToSend;
            }
          }
        } else {
          await questionnairesApi.editAnswerOption({
            eventId: this.eventId,
            questionnaireId: this.poll.id,
            questionId: question.id,
            answerOptionId: answerOption.id,
            text: answerOption.text,
            lang: this.currentEditLanguage === 'en' ? null : this.currentEditLanguage
          });
        }

        answerOptionIndex++;
      }

      questionIndex++;
    }
  }

  private isSavingAnswerOptionsNeeded(question: TQuestionnaireQuestion): boolean {
    const questionTypesWithOptions: QuestionTypes[] = [
      QuestionTypes.OPTION,
      QuestionTypes.MULTI,
      QuestionTypes.RATING
    ];
    return questionTypesWithOptions.indexOf(question.question_type) >= 0;
  }

  private addRatingOptions(question: TQuestionnaireQuestion): void {
    if (!this.isRatingOptionsListValid(question)) {
      if (question.answer_options && question.answer_options.length) {
        this.sanitizeRatingOptions(question);
      }
      const result: TQuestionnaireQuestionAnswerOption[] = [];
      for (let i = RATING_OPTIONS_FROM; i <= RATING_OPTIONS_TO; i++) {
        const newOption: TQuestionnaireQuestionAnswerOption = Object.assign({}, this.emptyAnswerOptionStruct);
        newOption.question_id = question.id;
        newOption.text = i.toFixed(0);
        newOption.id = 0 - (result.length + 1);
        result.push(newOption);
      }
      question.answer_options = result;
    }
  }

  private sanitizeRatingOptions(question: TQuestionnaireQuestion): void {
    const questionIndex = this.poll.questions.findIndex(q => q.id === question.id);
    let pos = question.answer_options.length;
    while (pos--) {
      this.deleteAnswerOption(questionIndex, pos);
    }
  }

  private isRatingOptionsListValid(question: TQuestionnaireQuestion): boolean {
    if (!question.answer_options || question.answer_options.length === 0) {
      return false;
    }

    const serializedOptions: string = question.answer_options.map(x => x.text).join(',');
    const serializedValidOptions: string = ((): string => {
      const result: number[] = [];
      for (let i = RATING_OPTIONS_FROM; i <= RATING_OPTIONS_TO; i++) {
        result.push(i);
      }
      return result.join(',');
    })();

    return serializedOptions === serializedValidOptions;
  }

  private emitSuccess(): void {
    const siteLang: string = this.$i18n.locale;
    const langCodeToEmit: string = this.eventLanguages.indexOf(siteLang) >= 0 ? siteLang : 'en';
    this.$emit('success', this.pollTranslations[langCodeToEmit]);
  }

  private onAddQuestionClick(): void {
    this.addQuestion();
    this.$nextTick(this.scrollQuestionListToBottom);
  }

  private scrollQuestionListToBottom(): void {
    try {
      const questionList: Element = this.$refs.questionList as Element;
      questionList.scrollTop = 1000000;
    } catch {
      /* ignore */
    }
  }

  private addQuestion(): void {
    const newQuestionDummy: TQuestionnaireQuestion = _cloneDeep(this.emptyQuestionStruct);
    newQuestionDummy.id = 0 - (1 + this.poll.questions.length);
    this.eventLanguages.forEach(langCode => {
      this.pollTranslations[langCode].questions.push(_cloneDeep(newQuestionDummy));
    });
  }

  private addAnAnswer(questionIndex: number): void {
    const question: TQuestionnaireQuestion = this.poll.questions[questionIndex];
    const newAnswerOptionDummy: TQuestionnaireQuestionAnswerOption = _cloneDeep(this.emptyAnswerOptionStruct);
    if (!question.answer_options) {
      this.eventLanguages.forEach(langCode => {
        this.pollTranslations[langCode].questions[questionIndex].answer_options = [];
      });
    }
    newAnswerOptionDummy.id = 0 - (1 + this.poll.questions[questionIndex].answer_options.length);
    newAnswerOptionDummy.question_id = question.id;
    this.eventLanguages.forEach(langCode => {
      this.pollTranslations[langCode].questions[questionIndex].answer_options.push(_cloneDeep(newAnswerOptionDummy));
    });
    this.pollTranslations = Object.assign({}, this.pollTranslations);
  }

  private toggleQuestionFolded(questionIndex: number): void {
    const questionBox: Element = (this.$refs.pollQuestionEditor as Element[])[questionIndex];
    const className = 'question-folded';
    if (questionBox.classList.contains(className)) {
      questionBox.classList.remove(className);
    } else {
      questionBox.classList.add(className);
    }
  }

  private onQuestionTypeClick(question: TQuestionnaireQuestion, questionIndex: number, targetQuestionTypeName: QuestionTypes): void {

    if (!question.answer_options || !question.answer_options.length) {
      this.eventLanguages.forEach(langCode => {
        this.pollTranslations[langCode].questions[questionIndex].question_type = targetQuestionTypeName;
      });
      this.unfoldQuestion(questionIndex);
      return;
    }

    this.newQuestionTypeName = targetQuestionTypeName;
    this.tempQuestionIndex = questionIndex;

    this.isTypeConfirmationVisible = true;

  }

  private changeTypeConfirmation(isConfirmed: boolean): void {

    const questionBeingEdited: TQuestionnaireQuestion = this.poll.questions[this.tempQuestionIndex];

    this.isTypeConfirmationVisible = false;

    if (isConfirmed) {
      if (
        questionBeingEdited
        && (questionBeingEdited.question_type !== this.newQuestionTypeName)
      ) {

        if (questionBeingEdited.answer_options) {
          let pos = questionBeingEdited.answer_options.length;
          while (pos--) {
            this.deleteAnswerOption(this.tempQuestionIndex, pos);
          }

          this.eventLanguages.forEach(langCode => {
            this.pollTranslations[langCode].questions[this.tempQuestionIndex].answer_options = [];
          });
        }

        this.unfoldQuestion(this.tempQuestionIndex);
        this.eventLanguages.forEach(langCode => {
          this.pollTranslations[langCode].questions[this.tempQuestionIndex].question_type = this.newQuestionTypeName;
        });

      }
    }

    this.newQuestionTypeName = QuestionTypes.NONE;
  }

  private unfoldQuestion(questionIndex: number): void {
    const questionBox: Element = (this.$refs.pollQuestionEditor as Element[])[questionIndex];
    const className = 'question-folded';
    questionBox.classList.remove(className);
  }

  private deleteQuestion(questionIndex: number): void {
    if (!this.poll.questions[questionIndex]) {
      return;
    }

    let isQuestionDeletionRequested = false;

    this.eventLanguages.forEach(langCode => {

      const question: TQuestionnaireQuestion = this.pollTranslations[langCode].questions[questionIndex];

      if (!isQuestionDeletionRequested && this.isEntityInDatabase(question)) {
        questionnairesApi.deleteQuestion({
          eventId: this.eventId,
          questionnaireId: this.poll.id,
          questionId: question.id
        });
        isQuestionDeletionRequested = true;
      }

      this.pollTranslations[langCode].questions.splice(questionIndex, 1);
    });

  }

  private deleteAnswerOption(questionIndex: number, answerOptionIndex: number): void {
    const question: TQuestionnaireQuestion = this.poll.questions[questionIndex];
    const questionId: number = question.id;
    const answerOption: TQuestionnaireQuestionAnswerOption = question.answer_options[answerOptionIndex];

    if (this.isEntityInDatabase(answerOption)) {
      questionnairesApi.deleteAnswerOption({
        eventId: this.eventId,
        questionnaireId: this.poll.id,
        questionId: questionId,
        answerOptionId: answerOption.id
      });
    }

    this.eventLanguages.forEach(langCode => {
      this.pollTranslations[langCode]
        .questions[questionIndex]
        .answer_options.splice(answerOptionIndex, 1);
    });

  }

  private isEntityInDatabase(entity: TQuestionnaire | TQuestionnaireQuestion | TQuestionnaireQuestionAnswerOption): boolean {
    return entity && entity.id && entity.id > 0;
  }

  private onLanguageTabClick(langCode: string): void {

    if (this.isLangTabDisabledByLangCode[langCode]) {
      return;
    }

    this.selectedLanguageTab = langCode;

    this.poll = Object.assign({}, this.pollTranslations[this.currentEditLanguage]);
  }

  /* Creates the following structure to be filled with poll translations:
      en: { ...poll },
      ...
   */
  private initPollTranslations(): void {
    this.eventLanguages.forEach(langCode => {
      // Using $set because of reactivity, see https://vuejs.org/v2/guide/reactivity.html#For-Objects
      this.$set(this.pollTranslations, langCode, _cloneDeep(this.emptyPollStruct));
    });
  }

  private async getExistingPollTranslations(): Promise<void> {
    if (!this.isEditMode) {
      this.updateLastSavedTranslations();
      this.isTranslationInitializationComplete = true;
      return;
    }

    for (const langCode of this.eventLanguages) {
      const existingPollTranslation: TQuestionnaire = await questionnairesApi.getQuestionnaire({
        eventId: this.eventId,
        questionnaireId: this.editingPoll.id,
        acceptLanguage: langCode
      });

      // For AW-2366, add empty arrays into free-text questions for Vue reactivity
      existingPollTranslation.questions
        .filter(q => q.question_type === QuestionTypes.TEXT)
        .forEach(q => {
          if (!q.answer_options) {
            q.answer_options = [];
          }
        });

      this.pollTranslations[langCode] = _cloneDeep(existingPollTranslation);
    }
    this.initForm();
    this.updateLastSavedTranslations();
    this.isTranslationInitializationComplete = true;
  }

  private updateLastSavedTranslations(lang?: string): void {
    if (lang && this.lastSavedPollTranslations[lang]) {
      this.lastSavedPollTranslations[lang] = _cloneDeep(this.pollTranslations[lang]);
      this.lastSavedPollTranslations = Object.assign({}, this.lastSavedPollTranslations);
      return;
    }
    this.lastSavedPollTranslations = _cloneDeep(this.pollTranslations);
  }

  private onCopyFromEnglishClick(): void {
    if (this.currentEditLanguage === 'en' || !this.pollTranslations || !this.pollTranslations.en) {
      return;
    }

    this.pollTranslations[this.currentEditLanguage] = _cloneDeep(this.pollTranslations.en);
  }
}
